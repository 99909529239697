<template>
  <div class="public-tabs-studioAdmin">
    <span class="tabs-item" :class="tabsIndex == index ? 'tabs-item-active' : ''" v-for="(item, index) in tabs"
      :key="index" @click="tabsChange(index)">
      <i class="item-icon"></i>
      <span class="item-text">{{ item.label }}<template v-if="item.count">({{ item.count }})</template></span>
      <i class="item-icon"></i>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: [],
    },
    newIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tabs: [],
      tabsIndex: 0,
    };
  },
  methods: {
    tabsChange(index) {
      this.tabsIndex = index;
      this.$emit('change', index);
    },
  },
  created() {
    this.tabs = this.list;
    if (this.newIndex) {
      this.tabsIndex = this.newIndex;
    }
  }
};
</script>

<style lang="less" scoped></style>